import React, { useEffect, useState, useMemo } from "react";
import api from "../components/api";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';



const UsuariosAnvisa = () => {
  const navigate = useNavigate();


  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Nome',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'ad_new',
      header: 'Data de Cadastro',
    },

    {
      accessorKey: 'gestor',
      header: 'É gestor?',
    },


    {
      accessorKey: 'cpf',
      header: 'CPF',
    },
    {
      accessorKey: 'dataNascimento',
      header: 'Data de Nascimento',
    },
    {
      accessorKey: 'nomeMae',
      header: 'Nome da Mãe',
    },
    {
      accessorKey: 'visa',
      header: 'Visa',
    },
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },
    {
      accessorKey: 'municipioDescricao',
      header: 'Município',
    },

  ], []);


  const [resultado, setResultado] = useState([]);
  const [totalUsuariosAnvisa, setTotalUsuariosAnvisa] = useState(0);
  const [slug, setSlug] = useState('')



  const visaLogin = sessionStorage.getItem('visaLogin')
  const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')
  const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')




  const carregaUsuariosAnvisa = () => {


    var dataPost = {

      visa: visaLogin,
      gvs_codigo: gvs_codigoLogin,
      ibge_codigo: ibge_codigoLogin,
    }


    api.post('usuariosAnvisa', dataPost)
      .then((result) => {
        const registros = result.data;
        setTotalUsuariosAnvisa(registros.length);
        setResultado(registros);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };






  useEffect(() => {

    carregaUsuariosAnvisa();

  }, []);



  return (

    <div>

      <div class="row">
        <div class="col-lg-12">

          <div data-collapsed="0" class="card">

            <div class="card-header">
              <div class="card-title">

                <h2 class="card-title"> Listagem de Usuários ANVISA</h2>
                <br />
                <span className="badge badge-success">{totalUsuariosAnvisa}</span>
              </div>
            </div>

            <div class="card-body">
              {/* <button
                type="button"
                className="btn btn-warning mt-2"
                onClick={() => navigate('/UsuarioAnvisa')}
              >
                + Adicionar novo usuário ANVISA
              </button> */}


              <div class="row">


                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => navigate(`/UsuarioAnvisa/${row.original.slug}`),
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />




              </div>

            </div>

          </div>
        </div>


      </div>

      <ToastContainer />


    </div>



  );
};

export default UsuariosAnvisa;
