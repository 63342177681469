
const Contato = () => {

    return (

        <div>

            Contato

        </div>

    )

}

export default Contato