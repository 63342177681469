import { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const MensagensFaleConosco = () => {

    const navigate = useNavigate();

    const columns = useMemo(() => [

        {
            accessorKey: 'nomeFaleConosco',
            header: 'Enviado por'
          },

          {
            accessorKey: 'emailFaleConosco',
            header: 'E-mail',
          },

        {
            accessorKey: 'assunto',
            header: 'Assunto'
          },
      
          {
            accessorKey: 'ad_new',
            header: 'Data de Envio',
          },
         


        ], []);

  

    const [resultado, setResultado] = useState([]);
    const [totalMensagensFaleConosco, setTotalMensagensFaleConosco] = useState(0);
   


        const listaMensagens = () =>{

            api.get(`faleConosco`).then((result) =>{
                const registros = result.data;
                //console.log(registros)
                setTotalMensagensFaleConosco(registros.length);
                setResultado(registros);

            })
            .catch((err) => {
                console.log(err.response);
            });
        };

        useEffect(() =>{

            listaMensagens()


        }, [])



    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div data-collapsed="0" className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="card-title">Listagem de Mensagens do Fale Conosco</h2>
                                <br />
                                <span className="badge badge-success">{totalMensagensFaleConosco}</span>
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
               
                            <br /><br />
                            <div className="row">
                                <MaterialReactTable
                                    columns={columns}
                                    data={resultado}
                                    localization={MRT_Localization_PT_BR}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                                xs: '8px',
                                                sm: '9px',
                                                md: '10px',
                                                lg: '11px',
                                                xl: '12px',
                                            },
                                        },
                                    }}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => navigate(`/MensagemFaleConosco/${row.original.faleConosco_id}`),
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MensagensFaleConosco;

