import React from "react";
import { useParams } from 'react-router-dom';

const AutoCadastro = () => {
    const { slug } = useParams();

    const mensagemGestorC = `\n\n\n\n\nPrezado(a), a homologação para uso deste Sistema Estadual será pelo CVS.`;
    const mensagemGestorG = `\n\n\n\n\nPrezado(a), a homologação para uso deste Sistema Estadual será pelo CVS.`;
    const mensagemGestorM = `\n\n\n\n\nPrezado(a), a liberação para seu acesso ao Sistema Estadual ocorrerá pelo GVS.`;

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center max-w-xl px-4">
                <h2 className="text-6xl font-bold leading-tight" style={{ whiteSpace: 'pre-wrap' }}>
                    {slug === 'C' && mensagemGestorC}
                    {slug === 'G' && mensagemGestorG}
                    {slug === 'M' && mensagemGestorM}
                    {slug === '0' && (
                        <>
                            {"\n\n\n\n\nPrezado(a), a aprovação de seu cadastro ocorrerá pelo seu Gestor e GVS. \n\nPosterior a sua aprovação, efetuar o cadastro no SNCR/Anvisa, pelo link: "}
                            <a
                                href="https://sncr.anvisa.gov.br/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#1E90FF', textDecoration: 'underline' }}
                            >
                                https://sncr.anvisa.gov.br/
                            </a>
                            {"."}
                        </>
                    )}
                </h2>
            </div>
        </div>
    );
}

export default AutoCadastro;
