
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

  if (sessionStorage.getItem('usuario_id')) {
 
    return children
    
  } else {

    return <Navigate to="/" />;

  }
  
};

export default ProtectedRoute