import { useEffect, useState } from "react";
import api from "../components/api";
import { useNavigate, useParams } from "react-router-dom";
import { validaCPF } from "../components/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


const TermoNotificacao = () => {


    ///Criar novo termo
    ///Extrai pdf de um usuário

    const navigate = useNavigate()

    const [nome, setNome] = useState('');


    const insereTermo = () => {

        /*

        var dataPost = {


            nome: nome,
            rg: rg,
            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            emailInstitucional:emailInstitucional,
            cargo: cargo,
            visa: visa,
            dataNascimento:dataNascimento,
            nomeMae: nomeMae,
            aceiteTermo: aceiteTermo,
            gvs_codigo: gvs_codigo,
            ibge_codigo: ibge_codigo,

        }

        */

        var dataPost = {}

        api.post(`termoNotificacao`, dataPost).then((result) => {

            console.log(result.data)
            var data = result.data.status;

            if (data === 'ok') {
                console.log('salvo com sucesso');
                toast.success('Termo de Notificação salvo com sucesso', { theme: "colored" });
                navigate('/TermoNotificacoes');
            } else {
                toast.error(data, { theme: 'colored' });
            }

        }).catch((err) => {
            toast.error('Erro ao salvar Termo de Notificação .' + err.message, { theme: "colored" });
            console.log(err.response);
        });
    }
    

    /*
    const pdfTermoNotificacao = () => {


        api.get(`termoNotificacaoPdf/${termoNotificacao_id}`).then((result) => {


            console.log(result.data)
            window.location.href = `${process.env.REACT_APP_API}/${'termoNotificacao.pdf'}`


        }).catch((err) => {

            console.log(err.response)


        })

    };
    */
        


        
    
    /*

    const carregaGvs = async () => {

        try {

            const result = await api.get('listaGvs');
            setListaGvs(result.data.map(rs =>

                <option value={rs.gvs_codigo}>{rs.descricao}</option>

            ));

        } catch (err) {

            console.log(err.result);

        }

    };

    
        
    const carregaMunicipio = async () => {

        try {

            const result = await api.get('listaIbgeMunicipios');
            setListaIbge(result.data.map(rs =>

                <option value={rs.ibge_codigo}>{rs.ibge_cidade}</option>

            ));

        } catch (err) {

            console.log(err.result);

        }

    };

    */



        /*async function carregaGvs() {
            try {
                const result = await api.get('listaGvs');
        
                setListaGvs(result.data.map(rs =>
                    <option value={rs.gvs_codigo}>{rs.descricao}</option>
                ));
            } catch (err) {
                console.log(err.result);
            }
        }
        
        async function carregaMunicipio() {
            try {
                const result = await api.get(`listaMunicipio/${gvs_codigo}`);
        
                setListaIbge(result.data.map(rs =>
                    <option value={rs.ibge_codigo}>{rs.ibge_cidade}</option>
                ));
            } catch (err) {
                console.log(err.result);
            }
        }*/

        



        


        useEffect(() => {

            
            //carregaGvs();
            //carregaMunicipio();

            //if (usuarioAnvisa_id) {

              //  carregaUsuario()

            //}
            
        }, [])


        return (
            <div>
                <div className="row justify-content-center" >
                    <div className="col-lg-12" >
                        <div data-collapsed="0" className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2 className="card-title">Novo Termo de Notificação</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                <div className="grid grid-cols-12 gap-x-5">
                                <div className="lg:col-span-9 md:col-span-6 col-span-12">
                            <div className="cashier-select-field mb-5">
                                <h5 className="text-[15px] text-heading font-semibold mb-3">Termo</h5>
                                <div className="cashier-input-field-style">
                                    <div className="single-input-field w-full">
                                        <input type="text" placeholder="" value={nome} onChange={(e) => setNome(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                                     <br />
                                    <div className="cashier-managesale-top-btn default-light-theme pt-2.5">
                                        <button className="btn-warning" class="btn btn-info mt-2" onClick={insereTermo}>Salvar</button>&nbsp;
                                        <button className="btn-primary" class="btn btn-primary mt-2" onClick={() => navigate('/TermoNotificacoes')} >Voltar</button>&nbsp;
                                        </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
                </div>
            </div>
        );
    };
    
    

    export default TermoNotificacao;
