import { useState, useEffect } from "react"
import api from "../components/api"
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


const Farmacia = () => {

    const { farmacia_id } = useParams()

    const navigate = useNavigate()

    const [listaUf, setListaUf] = useState([])
    const [listaMunicipio, setListaMunicipio] = useState([])

    const [uf_codigo, setUf_codigo] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnes, setCnes] = useState('');
    const [cevs, setCevs] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [ibgeMun, setIbgeMun] = useState('');
    const [municipio, setMunicipio] = useState('');


    const carregaUf = () => {

        api.get('listaUf').then((result) => {

            var registros = result.data

            setListaUf(registros.map((item) =>

                <option key={item.uf_codigo} value={item.uf_codigo}>
                    {item.uf_codigo}
                </option>

            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }

    const carregaMunicipios = () => {

        api.get('listaPorUf/' + uf_codigo).then((result) => {

            var registros = result.data

            setListaMunicipio(registros.map((item) =>

                <option key={item.mn_descri} value={item.mn_descri}>{item.mn_descri}</option>

            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }




    const salvaFarmacia = () => {

        if (cnpj === '') {

            toast.error('Campo CNPJ não pode ficar em branco!', { theme: 'colored' })
            return false
        }


        if (razaoSocial === '') {

            toast.error('Campo Razão social não pode ficar em branco!', { theme: 'colored' })
            return false
        }


        if (nomeFantasia === '') {

            toast.error('Campo nome fantasia não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (uf_codigo === '') {

            toast.error('Campo UF não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        
        if (municipio === '') {

            toast.error('Campo município não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (cnes === '') {

            toast.error('Campo CNES não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (cevs === '') {

            toast.error('Campo CEVS não pode ficar em branco!', { theme: 'colored' })
            return false

        }

        if (cep === '') {

            toast.error('Campo CEP não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (logradouro === '') {

            toast.error('Campo logradouro não pode ficar em branco!', { theme: 'colored' })
            return false

        }

        if (numero === '') {

            toast.error('Campo número não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (telefone === '') {

            toast.error('Campo telefone não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        if (email === '') {

            toast.error('Campo E-mail não pode ficar em branco!', { theme: 'colored' })
            return false
        }

        

        //if (ibgeMun)/


        var dataPost = {

            cnpj: cnpj,
            razaoSocial: razaoSocial,
            nomeFantasia: nomeFantasia,
            cnes: cnes,
            cevs: cevs,
            cep: cep,
            logradouro: logradouro,
            numero: numero,
            uf_codigo: uf_codigo,
            telefone: telefone,
            ibgeMun: ibgeMun,
            email: email, 
            ad_usr: sessionStorage.getItem('usuario_id')

        }

        if (farmacia_id) {

            api.put(`farmacia/${farmacia_id}`, dataPost).then((result) => {

                console.log(result.data)

                toast.success('Farmácia salva com sucesso!', { theme: "colored" });

            }).catch((err) => {

                console.log(err.response)
            })

        } else {

            var dataPost = {
            cnpj:cnpj,
            razaoSocial: razaoSocial,
            nomeFantasia: nomeFantasia,
            cnes: cnes,
            cevs: cevs,
            cep: cep,
            logradouro: logradouro,
            numero: numero,
            telefone: telefone,
            ibgeMun: ibgeMun,
            email: email
        }


            api.post(`farmacia`, dataPost).then((result) => {

                var data = result.data.alert

                if (data === 'ok') {

                    toast.success('Farmácia salva com sucesso!', { theme: "colored" });
                    navigate('/farmacia')

                } else {

                    toast.error('Erro ao salvar farmácia')

                }

            }).catch((err) => {

                toast.error('Erro ao salvar Farmácia!' + err.message, { theme: "colored" });

            })

        }


    }



    const carregaFarmacia = () => {

        api.get(`farmacia/${farmacia_id}`).then((result) => {

            var registro = result.data[0]
            setCnpj(registro.cnpj)
            setRazaoSocial(registro.razaoSocial)
            setNomeFantasia(registro.nomeFantasia)
            setCnes(registro.cnes)
            setCevs(registro.cevs)
            setCep(registro.cep)
            setLogradouro(registro.logradouro)
            setNumero(registro.numero)
            setTelefone(registro.telefone)
            setEmail(registro.email)
            setUf_codigo(registro.uf_codigo)
            setMunicipio(registro.municipio)

        }).catch((err) => {

            console.log(err.response) //ok

        })


    }


    useEffect(() => {

        if (farmacia_id) {

            carregaFarmacia()
            carregaUf()

        }
    }, [farmacia_id])




    useEffect(() => {

        carregaUf()

    }, [])

    useEffect(() => {

        carregaMunicipios()

    }, [uf_codigo])






    useEffect(() => {

        if (farmacia_id) {

            carregaMunicipios()
        }

    }, [farmacia_id])






    const apagaFarmacia = () => {

        if (farmacia_id) {

            api.delete(`farmacia/${farmacia_id}`).then((result) => {

                console.log(result.data)
                toast.success('Farmácia deletada com sucesso!', { theme: "colored" });

            }).catch((err) => {

                toast.error('Erro ao deletar farmácia!', { theme: "colored" });
                console.log()
            })


        }


    }


    return (

        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div data-collapsed="0" className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="card-title">Farmácia</h2>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CNPJ: *</label>
                                        <input type="text" className="form-control" value={cnpj} onChange={event => setCnpj(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={14} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Razão Social: *</label>
                                        <input type="text" className="form-control" value={razaoSocial} onChange={event => setRazaoSocial(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Nome Fantasia: *</label>
                                        <input type="text" className="form-control" value={nomeFantasia} onChange={event => setNomeFantasia(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">UF: *</label>
                                        {/* <input type="text" className="form-control" value={uf} onChange={event => setUf(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={2} /> */}
                                        <select className="form-control" value={uf_codigo} onChange={event => setUf_codigo(event.target.value)}>
                                            <option key="0" value="0">
                                                [SELECIONE]
                                            </option>
                                            {listaUf}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Municípios: *</label>
                                        {/* <input type="text" className="form-control" value={uf} onChange={event => setUf(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={2} /> */}
                                        <select className="form-control" value={municipio} onChange={event => setMunicipio(event.target.value)}>
                                            <option key="0" value="0">
                                                [SELECIONE]
                                            </option>
                                            {listaMunicipio}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CNES: *</label>
                                        <input type="text" className="form-control" value={cnes} onChange={event => setCnes(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CEVS: *</label>
                                        <input type="text" className="form-control" value={cevs} onChange={event => setCevs(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>


                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">CEP: *</label>
                                        <input type="text" className="form-control" value={cep} onChange={event => setCep(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={8} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Logradouro: *</label>
                                        <input type="text" className="form-control" value={logradouro} onChange={event => setLogradouro(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Número: *</label>
                                        <input type="text" className="form-control" value={numero} onChange={event => setNumero(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">Telefone: *</label>
                                        <input type="text" className="form-control" value={telefone} onChange={event => setTelefone(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput">E-mail: *</label>
                                        <input type="text" className="form-control" value={email} onChange={event => setEmail(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>



                                <div className="col-lg-5">
                                    <br />
                                    <button type="button" className="btn btn-info mt-2" onClick={() => navigate('/farmacias')}>Voltar</button>&nbsp;
                                    <button type="button" className="btn btn-danger mt-2" onClick={() => apagaFarmacia()} style={{ display: farmacia_id ? 'table-row' : 'none' }}>Apagar</button>&nbsp;
                                    <button type="button" className="btn btn-success mt-2" onClick={() => salvaFarmacia()}>Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />

        </div>

    )
}




export default Farmacia