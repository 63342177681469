import { useState, useEffect, useMemo } from 'react'
import api from "../components/api"
import { useNavigate, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Medicos = () => {

    const navigate = useNavigate()

    const columns = useMemo(() => [
        {
          accessorKey: 'nome',
          header: 'Nome',
        },
        {
          accessorKey: 'cpf',
          header: 'CPF',
        },
        {
          accessorKey: 'crm',
          header: 'CRM',
        },
        {
          accessorKey: 'email',
          header: 'E-mail',
        },
        
        
      ], []);


    const [resultado, setResultado] = useState([])
    const [totalMedicos, setTotalMedicos] = useState(0)
    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [crm, setCrm] = useState('')
    const [uf, setUf] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [ad_upd, setAd_upd] = useState('')
    const [ad_usr, setAd_usr] = useState('')

        


    const apagaMedico = (medico_id) => {

            //console.log(medico_id)
            api.delete(`medico/${medico_id}`).then((result) => {

                console.log(result.data)
                carregaMedicos()

            }).catch((err) => {

                console.log(err.response)

            })


        }


    const medicoExtraiExcel = () => {

        api.get(`medicoExtraixcelE`).then((result) => {

            console.log(result.data)
            window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`


        }).catch((err) => {

            console.log(err.response)

        })

    }

    const dataPost = {
      nome: nome,
      cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
      crm: crm,
      uf: uf,
      email: email,
      telefone: telefone,
      ad_upd: ad_upd,
      ad_usr: ad_usr,
    };



    const carregaMedicos = () => {

        api.get('medicos', dataPost)
        .then((result) => {
            //console.log(result.data)
            const registros = result.data
            setTotalMedicos(registros.length)
            // propriedade map = renderiza arrays em html

            setResultado(registros);

        })
        .catch((err) => {
            console.log(err.response)
        });
    };


    // ao carregar a página

    useEffect(() => {

        carregaMedicos()

    }, [])



    return (


        <div>




            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title"> Listagem Médicos</h2> <br />
                                <span class="badge badge-success">{totalMedicos}</span>

                            </div>
                        </div>

                        <div class="card-body">
                            <button type="button" class="btn btn-warning mt-2" onClick={() => navigate('/Medico')} >+ Novo médico</button>&nbsp;
                            <button type="button" class="btn btn-success mt-2" onClick={() => medicoExtraiExcel()} >Exporta Excel</button>

                            <br /><br />
                            <div class="row">



                            <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => navigate(`/Medico/${row.original.medico_id}`),
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />




              </div>

            </div>

          </div>
        </div>


      </div>

      <ToastContainer />


    </div>



  );
};

export default Medicos
