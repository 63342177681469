import { useState, useEffect, useMemo } from 'react'
import api from "../components/api"
import { useNavigate, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Farmacias = () => {


    const navigate = useNavigate()

    const columns = useMemo(() => [
        {
          accessorKey: 'cnpj',
          header: 'CNPJ',
        },
        {
          accessorKey: 'razaoSocial',
          header: 'Razão Social',
        },
        {
          accessorKey: 'nomeFantasia',
          header: 'Nome Fantasia',
        },
        {
          accessorKey: 'email',
          header: 'E-mail',
        },
        
      ], []);



    const [cnpj, setCnpj] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [uf, setUf] = useState('');
    const [ibgeMun, setIbgeMun] = useState('');
    const [cnes, setCnes] = useState('');
    const [cevs, setCevs] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');

    const [resultado, setResultado] = useState([])
    const [totalFarmacias, setTotalFarmacias] = useState(0)

    const apagaFarmacia = (farmacia_id) => {

        api.delete(`farmacia/${farmacia_id}`).then((result) => {

            console.log(result.data)
            carregaFarmacias()

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const farmaciaExtraiExcel = () => {

        api.get(`farmaciaExtraiExcel`).then((result) => {

            console.log(result.data)
            window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const dataPost = {
        cnpj: cnpj,
        razaoSocial: razaoSocial,
        nomeFantasia: nomeFantasia,
        uf: uf,
        ibgeMun: ibgeMun,
        cnes: cnes,
        cevs: cevs,
        cep: cep,
        logradouro: logradouro,
        numero: numero,
        telefone: telefone,
        email: email,

        
      }; 



    const carregaFarmacias = () => {

        //console.log('!')

        api.get('farmacias', dataPost).then((result) => {

            console.log(result.data)

            var registros = result.data
            setTotalFarmacias(registros.length)
            // propriedade map = renderiza arrays em html

            setResultado(registros);

        }).catch((err) => {

            console.log(err.response)
        })

    }

    useEffect(() => {

        carregaFarmacias()

    }, [])


    return (


        <div>

            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title"> Listagem Farmácias</h2> <br />
                                <span class="badge badge-success">{totalFarmacias}</span>

                            </div>
                        </div>

                        <div class="card-body">
                            <button type="button" class="btn btn-warning mt-2" onClick={() => navigate('/Farmacia')} >+ Nova Farmácia</button>&nbsp;
                            <button type="button" class="btn btn-success mt-2" onClick={() => farmaciaExtraiExcel()} >Exporta Excel</button>

                            <br /><br />
                            <div class="row">



                            <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => navigate(`/UsuarioAnvisa/${row.original.usuarioAnvisa_id}`),
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />




              </div>

            </div>

          </div>
        </div>


      </div>

      <ToastContainer />


    </div>



  );
};
export default Farmacias