import { useEffect, useState } from "react"
import api from "../components/api"
import { useNavigate, useParams } from "react-router-dom"
import { validaCPF } from "../components/functions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  //---ok
//import Modal from '../components/Modal';
import React from 'react';
import InputMask from 'react-input-mask';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
//import { emailPendente } from "../api/controller/usuarioController";


const Usuario = () => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isModalOpen, setModalOpen] = useState(false);

    const { usuario_id } = useParams() //---ok

    const navigate = useNavigate()

    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [visa, setVisa] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [gvs_codigo, setGvsCodigo] = useState('');
    const [listaIbge, setListaIbge] = useState('');
    const [listaGvs, setListaGvs] = useState('');
    const [ibge_codigo, setIbgeCodigo] = useState('');
    const [ibge_cidade, setIbgeCidade] = useState('');
    const [telefoneCelular, setTelefoneCelular] = useState('');
    const [status, setStatus] = useState('');
    const [listaIbgeMunicipios, setListaIbgeMunicipios] = useState('');
    const [listaStatus, setListaStatus] = useState('');
    const [enviadoSimNao, setEnviadoSimNao] = useState('');
    //const [listaEnviadoSimNao, setListaEnviadoSimNao] = useState('');
    const [emailPendente, setEmailPendente] = useState('');


    //const [crm, setCrm] = useState('');
    //const [uf, setUf] = useState('');


    const [confirmaSenha, setConfirmaSenha] = useState('');

    const ad_usr = sessionStorage.getItem('usuario_id')


    //use effects
    //-----------------------------------------

    useEffect(() => {

        if (visa === '' || visa === 'C') {
            setGvsCodigo('');
            setIbgeCodigo('');
        }

        if (visa === 'G') {
            setIbgeCodigo('');
        }



    }, [visa])


    useEffect(() => {

        carregaMunicipio()

    }, [gvs_codigo])


    useEffect(() => {

        carregaGvs()

    }, [])


    useEffect(() => {

        if (show === false) {

            setSenha('');
            setConfirmaSenha('');
        }

    }, [show]);


    useEffect(() => {

        if (usuario_id) {

            carregaUsuario()

        }

    }, [usuario_id])


    const ReenvioCredenciais = () => {

        var dataPost = {
            ad_usr: ad_usr,
            usuario_id: usuario_id
        }

        api.post(`reenvioCredenciais`, dataPost).then((result) => {

            var data = result.data.status;

            if (data === 'ok') {
                toast.success('E-mail de credencias enviado com sucesso!', { theme: "colored" });
            } else {
                var descricaoErro = result.data.descricao
                toast.error('Erro. ' + descricaoErro, { theme: 'colored' });
            }
        }).catch((err) => {
            console.log(err.response)
            toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
        });


    };


    async function carregaGvs() {
        try {
            const result = await api.get('listaGvs');

            setListaGvs(result.data.map(rs =>
                <option value={rs.gvs_codigo}>{rs.descricao}</option>
            ));
        } catch (err) {
            console.log(err.result);
        }
    }

    async function carregaMunicipio() {
        try {
            const result = await api.get(`listaMunicipio/${gvs_codigo}`);

            setListaIbge(result.data.map(rs =>
                <option value={rs.ibge_codigo}>{rs.ibge_cidade}</option>
            ));
        } catch (err) {
            console.log(err.result);
        }
    }


    const validaCPFUsuario = () => {


        var validaCPF = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            usuario_id: usuario_id,

        }


        api.get(`usuarioVerificaCpf`, validaCPF).then((result) => {

            //console.log(result.data)

            if (result.data.status !== 'ok') {

                toast.error(result.data.descricao, { theme: 'colored' })
            }
        }).catch((err) => {

            console.log(err.response)
        })

    }



    const salvaUsuario = () => {

        if (!validaCPF || '') {

            toast.error('CPF inválido!', { theme: 'colored' }) // - ok
            return false
        }

        if (nome === '') {

            toast.error('Nome não pode ficar em branco!', { theme: 'colored' }) // - ok
            return false
        }

        if (gvs_codigo === '' && visa === 'G') {
            toast.error('Por favor, insira o GVS.', { theme: 'colored' });
            return false;
        }

        if (ibge_codigo === '' && visa === 'M') {
            toast.error('Por favor, insira o Município.', { theme: 'colored' });
            return false;
        }

        if (email === '') {
            toast.error('Por favor, insera seu e-mail.', { theme: 'colored' }) // - ok
            return false
        }

        if (telefoneCelular === '') {
            toast.error('Por favor, insira seu telefone celular.', { theme: 'colored' }) // - ok
            return false
        }

        if (visa === 'G' && gvs_codigo === '') {
            toast.error('Por favor, selecione o GVS.', { theme: 'colored' });
            return false;
        }

        if (visa === 'M' && ibge_codigo === '') {
            toast.error('Por favor, selecione o Município.', { theme: 'colored' });
            return false;
        }

        if (status === '') {
            toast.error('Por favor, selecione um status.', { theme: 'colored' });
            return false;
        }

        /*
        if (enviadoSimNao === '') {
            toast.error('Por favor, selecione se o e-mail já foi enviado ou não.', { theme: 'colored' });
            return false;
        }
        */



        window.$.confirm({
            title: 'Confirmação',
            content: 'Confirma salvar?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter', 'shift'],
                    action: function () {

                        // inserir ação para sim

                        if (usuario_id) { // ok

                            var dataPost = {
                                nome: nome,
                                cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                                email: email,
                                telefoneCelular: telefoneCelular,
                                visa: visa,
                                gvs_codigo: gvs_codigo,
                                status: status,
                                ibge_codigo: ibge_codigo,
                                ad_usr: sessionStorage.getItem('usuario_id')
                            }

                            //console.log(dataPost)
                            //return false

                            api.put(`usuario/${usuario_id}`, dataPost).then((result) => {
                                var data = result.data.status;

                                if (data === 'ok') {
                                    toast.success('Usuário salvo com sucesso', { theme: "colored" });
                                } else {
                                    toast.error(data, { theme: 'colored' });
                                }
                            }).catch((err) => {
                                console.log(err.response)
                                toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                            });

                        } else {  // Remover o ponto e vírgula aqui

                            var dataPost = {
                                // ok
                                nome: nome,
                                cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                                senha: senha,
                                email: email,
                                telefoneCelular: telefoneCelular,
                                visa: visa,
                                //emailPendente: emailPendente,
                                gvs_codigo: gvs_codigo,
                                status: status,
                                ibge_codigo: ibge_codigo,
                                ad_usr: sessionStorage.getItem('usuario_id')
                            };

                            api.post(`usuario`, dataPost).then((result) => {
                                console.log(result.data)
                                var data = result.data.status;

                                if (data === 'ok') {
                                    console.log('salvo com sucesso');
                                    toast.success('Usuário salvo com sucesso', { theme: "colored" });
                                    navigate('/Usuarios');
                                } else {
                                    toast.error(data, { theme: 'colored' });
                                }

                            }).catch((err) => {
                                toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                                console.log(err.response);
                            });
                        }

                    }
                },
                cancel: {
                    text: 'Não',
                    btnClass: 'btn-red',
                    keys: ['enter', 'shift'],
                    action: function () {

                        // inserir ação para nao
                        //console.log('cancelado pelo usuário')

                    },
                },

            }
        });
    }







    const carregaUsuario = () => {

        api.get(`usuario/${usuario_id}`).then((result) => {

            var registro = result.data[0]

            //console.log(registro)

            setNome(registro.nome)
            setCpf(registro.cpf)
            setEmail(registro.email)
            setTelefoneCelular(registro.telefoneCelular)
            setVisa(registro.visa)
            setGvsCodigo(registro.gvs_codigo)
            setStatus(registro.status)
            //setEnviadoSimNao(registro.emailEnviadoSimNao)
            setEmailPendente(!registro.emailPendente ? true : false)
            setIbgeCodigo(registro.ibge_codigo)

        }).catch((err) => {

            console.log(err.response) // ok

        })

    }


    const apagaUsuario = () => {

        if (usuario_id) {

            api.delete(`usuario/${usuario_id}`).then((result) => {

                console.log(result.data)

                toast.success('Usuário deletado com sucesso!', { theme: "colored" });

            }).catch((err) => {
                console.log()

            })

        }

    }

    const salvaSenhaModal = async () => {
        if (senha !== confirmaSenha) {
            toast.error('As senhas não coincidem!', { theme: 'colored' });
            console.log('Erro: as senhas não coincidem');
            return false;
        }

        const dataPost = {
            senha: senha,
            confirmaSenha: confirmaSenha,
        };

        try {
            const result = await api.post(`usuarioAlteraSenha/${usuario_id}`, dataPost);
            console.log(result.data);
            handleClose();
            toast.success('Senha alterada com sucesso!', { theme: 'colored' });
        } catch (err) {
            console.log(err.response);
            toast.error('Erro ao alterar a senha. Tente novamente.', { theme: 'colored' });
        }
    };


    /*
    const emailEnviadoSimNao = async () => {


        var dataPost = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            email: email,
        }

        api.put(`emailPendente/${usuario_id}`, dataPost).then((result) => {
            var data = result.data.status;

            if (data === 'ok') {
                toast.success('Ok', { theme: "colored" });
            } else {
                toast.error(data, { theme: 'colored' });
            }
        }).catch((err) => {
            console.log(err.response)
            toast.error('Erro.' + err.message, { theme: "colored" });
        });

    }
    */

    return (

        <div>
            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title">Usuário</h2>

                            </div>
                        </div>



                        <div class="card-body">

                            <div class="row">

                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="col-form-label">CPF: *</label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            type="text"
                                            className="form-control"
                                            value={cpf}
                                            disabled={!!usuario_id} // Converts `usuario_id` to a boolean
                                            onChange={event => {setCpf(event.target.value);}}
                                            onBlur={validaCPFUsuario}
                                            placeholder=""
                                        />
                                    </div>
                                </div>



                                <div class="col-lg-9">
                                    <div class="form-group">
                                        <label class="col-form-label">Nome: *</label>
                                        <input type="text" class="form-control" value={nome} onChange={event => setNome(event.target.value)} placeholder="" maxLength={255} />
                                    </div>
                                </div>




                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">E-mail: *</label>
                                        <input type="text" class="form-control" value={email} onChange={event => setEmail(event.target.value)} placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label">Telefone celular: *</label>
                                        <input type="text" class="form-control" value={telefoneCelular} onChange={event => setTelefoneCelular(event.target.value)} placeholder="" maxLength={25} />
                                    </div>
                                </div>




                                {/*--------------------------------------------------------------------*/}



                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Status: *</label>
                                        <select className="form-control" value={status} onChange={event => setStatus(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="A">Ativo</option>
                                            <option value="I">Inativo</option>
                                            {listaStatus}
                                        </select>
                                    </div>
                                </div>



                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">Visa: *</label>
                                        <select className="form-control" value={visa} onChange={event => setVisa(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="C">CVS</option>
                                            <option value="G">GVS</option>
                                            <option value="M">Municipal</option>
                                            <option value="A">Administrador</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >GVS: </label>
                                        <select
                                            className="form-control"
                                            value={gvs_codigo}
                                            onChange={event => setGvsCodigo(event.target.value)}
                                            disabled={visa === '' || visa === 'C' || visa === 'A'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaGvs}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4" >Município: </label>
                                        <select
                                            className="form-control"
                                            value={ibge_codigo}
                                            onChange={event => setIbgeCodigo(event.target.value)}
                                            disabled={gvs_codigo === '' || visa === '' || visa === 'G' || visa === 'A'}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaIbge}
                                        </select>
                                    </div>
                                </div>


                                <div className="col-lg-12" style={{ display: usuario_id ? 'table-row' : 'none' }}>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">E-mail de primeiro acesso enviado:</label>
                                        <select className="form-control" value={emailPendente} disabled={true} >
                                            <option value="">[Selecione]</option>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </div>
                                </div>


                                {/*--------------------------------------------------------*/}


                                <div class="col-lg-6" style={{ display: usuario_id ? 'none' : 'table-row' }}>
                                    <div class="form-group">
                                        <label class="col-form-label">Senha: *</label>
                                        <input type="password" class="form-control" value={senha} onChange={event => setSenha(event.target.value)} placeholder="" maxLength={25} />
                                    </div>
                                </div>


                                <div class="col-lg-6" style={{ display: usuario_id ? 'none' : 'table-row' }}>
                                    <div class="form-group">
                                        <label class="col-form-label">Confirma senha: *</label>
                                        <input type="password" class="form-control" value={confirmaSenha} onChange={event => setConfirmaSenha(event.target.value)} placeholder="" maxLength={25} />
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <br />
                                    <button type="button" className="btn btn-warning mt-2" onClick={() => navigate('/Usuarios')} >Voltar</button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-success mt-2" onClick={salvaUsuario}>Salvar</button>&nbsp;&nbsp;
                                    {/* <button type="button" className="btn btn-danger mt-2" style={{ display: sessionStorage.getItem('perfilLogin') == 'A' ? '' : 'none' }} onClick={apagaUsuario}>Excluir</button>&nbsp;&nbsp; */}
                                    <button type="button" className="btn btn-primary mt-2" style={{ display: usuario_id ? '' : 'none' }} onClick={handleShow}>Alterar senha</button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn btn-info mt-2" onClick={ReenvioCredenciais} style={{ display: sessionStorage.getItem('visaLogin') == 'C' || sessionStorage.getItem('visaLogin') == 'G' ? '' : 'none' }}>Reenviar E-mail de Credenciais </button>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Alterar Senha</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">

                        <div class="col-lg-6" >
                            <div class="form-group">
                                <label class="col-form-label"><b>Senha: *</b></label>
                                <input type="password" class="form-control" value={senha} onChange={event => setSenha(event.target.value)} placeholder="" maxLength={25} />
                            </div>
                        </div>


                        <div class="col-lg-6" >
                            <div class="form-group">
                                <label class="col-form-label"><b>Confirma senha: *</b></label>
                                <input type="password" class="form-control" value={confirmaSenha} onChange={event => setConfirmaSenha(event.target.value)} placeholder="" maxLength={25} />
                            </div>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="success" onClick={() => salvaSenhaModal()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />

        </div>


    );

}

export default Usuario
