import { useEffect, useState } from "react"
import api from "../components/api"
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificacaoRange = () => {




}



export default NotificacaoRange