import React, { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const NovosCadastros = () => {

  const [rowSelection, setRowSelection] = useState({})

  const navigate = useNavigate();

  const visaLogin = sessionStorage.getItem('visaLogin')
  const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')
  const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')


  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Usuário',
    },

    {
      accessorKey: 'status',
      header: 'Status',
    },

    {
      accessorKey: 'dataCadastro',
      header: 'Data e Hora de Cadastro',
    },

    {
      accessorKey: 'visa',
      header: 'VISA',
    },
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },

    {
      accessorKey: 'IBGE_CIDADE',
      header: 'Município',
    },
  ], []);

  const [resultado, setResultado] = useState([]);
  const [totalUsuariosAnvisa, setTotalUsuariosAnvisa] = useState(0);
  const [slug, setSlug] = useState('')



  const listaNovosCadastros = () => {

    var dataPost = {

      visa: visaLogin,
      gvs_codigo: gvs_codigoLogin,
      ibge_codigo: ibge_codigoLogin

    }

    api.post(`listaNovosCadastros`, dataPost).then((result) => {
      const registros = result.data;
      console.log(registros)
      setResultado(registros);

    })
      .catch((err) => {
        console.log(err.response);
      });
  };


  useEffect(() => {
    listaNovosCadastros();
  }, []);



  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div data-collapsed="0" className="card">
            <div className="card-header">
              <div className="card-title">
                <h2 className="card-title">Novos Cadastros</h2>
                <br />
              </div>
            </div>

            <div className="card-body">



              <div className="row">
                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  enableRowSelection={false}
                  getRowId={(row) => row.historico_id} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => navigate(`/UsuarioAnvisa/${row.original.slug}`),
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};



export default NovosCadastros;
