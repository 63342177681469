import { useEffect, useState, useMemo } from 'react';
import api from "../components/api";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Historico = () => {

  const [rowSelection, setRowSelection] = useState({})

  const navigate = useNavigate();

  const [usuarioAnvisa_id, setUsuarioAnvisa_id] = useState('');
  const [status, setStatus] = useState('');
  const [dataHora, setDataHora] = useState('');
  const [publicado, setPublicado] = useState('');
  const [publicacao_id, setPublicacao_id] = useState('');
  const [totalHistorico, setTotalHistorico] = useState('');
  const [resultado, setResultado] = useState([]);
  const [visa, setVisa] = useState('');
  const [gvs_descricao, setGvsDescricao] = useState('');
  const [numeroPublicacao, setNumeroPublicacao] = useState('');
  const [enviado, setEnviado] = useState('');
  const [totalNaoPublicadosAnvisa, setTotalNaoPublicadosAnvisa] = useState('');

  // Definição das colunas na tabela
  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Usuário',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'visa',
      header: 'VISA',
    },
    {
      accessorKey: 'gvs_descricao',
      header: 'GVS',
    },
    {
      accessorKey: 'IBGE_CIDADE',
      header: 'Município',
    },
  ], []);



  const listaNaoPublicados = () => {
    api.get(`naoPublicados`).then((result) => {
      const registros = result.data;
      console.log('aqui2: ' + registros)
      setTotalNaoPublicadosAnvisa(registros.length);
      setResultado(registros);

    })
      .catch((err) => {
        console.log(err.response);
      });
  };




  useEffect(() => {
    listaNaoPublicados();
  }, []);



  const publicaSelecionados = () => {

    //console.log(rowSelection)
    //return false

    const keys = Object.keys(rowSelection);
    const numericKeys = keys.map(Number);

    var dataPost = {

      historico_id_array: numericKeys,

    };

    if (numericKeys.length == 0) {

      toast.error('Selecione ao menos um registro para publicar.', { theme: "colored" });
      return;

    } else {

      window.$.confirm({
        title: 'Confirmação',
        content: `Confirma publicar ${numericKeys.length} registro(s)?`,
        buttons: {
          yes: {
            text: 'Sim',
            btnClass: 'btn-green',
            keys: ['enter', 'shift'],
            action: function () {

              api.post(`publicacao`, dataPost).then((result) => {

                console.log(result.data);

                var data = result.data.status;
                var publicacao_id = result.data.publicacao_id;

                if (data === 'ok') {

                  console.log('salvo com sucesso');
                  toast.success('Publicação enviada com sucesso!', { theme: "colored" });
                  navigate(`/publicacao/${publicacao_id}`);

                } else {
                  toast.error(data, { theme: 'colored' });
                }
              }).catch((err) => {
                toast.error('Erro ao salvar o usuário.' + err.message, { theme: "colored" });
                console.log(err.response);
              });



            }
          },
          cancel: {
            text: 'Não',
            btnClass: 'btn-red',
            keys: ['enter', 'shift'],
            action: function () {

              // inserir ação para nao
              //console.log('cancelado pelo usuário')

            },
          },

        }
      });



    }

  }




  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div data-collapsed="0" className="card">
            <div className="card-header">
              <div className="card-title">
                <h2 className="card-title">Registros a serem publicados</h2>
                <br />
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-info mt-2 custom-button"
                  onClick={() => publicaSelecionados()}
                >
                  Publicar selecionados
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-warning mt-2 custom-button"
                  onClick={() => navigate('/ListaPublicacoes')}
                >
                  Voltar
                </button>
              </div>

              <br /><br />
              <div className="row">
                <MaterialReactTable
                  columns={columns}
                  data={resultado}
                  localization={MRT_Localization_PT_BR}
                  initialState={{ density: 'compact' }}
                  enableRowSelection
                  getRowId={(row) => row.historico_id} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //onClick: () => navigate(`/DetalhesHistorico/${row.original.historico_id}`),
                    sx: {
                      //cursor: 'pointer',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};



export default Historico;
