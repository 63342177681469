import { useState, useEffect } from "react"
import api from "../components/api"
import { useNavigate, useParams } from "react-router-dom"
import { validaCPF } from "../components/functions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  //---ok
import InputMask from 'react-input-mask';


const Medico = () => {

    const { medico_id } = useParams() //---ok

    const navigate = useNavigate()

    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [crm, setCrm] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [uf_codigo, setUf_codigo] = useState('');
    const [senha, setSenha] = useState('');
    const [ad_usr, setAd_usr] = useState('');

    const [listaUf, setListaUf] = useState('');

    const [confirmaSenha, setConfirmaSenha] = useState('');



    
    async function carregaUf() {

        await api.get('listaUf').then((result) => {

            setListaUf(result.data.map((rs =>

                <option value={rs.uf_codigo}>{rs.uf_codigo}</option>

            ))
        )

        }).catch((err) => {

            console.log(err.result)

        })

    }




    const salvaMedico = () => {

        //fazer esse toast para todos os campos//


        if (!validaCPF || '') {

            toast.error('CPF inválido!', { theme: 'colored' }) // - ok
            return false
        }


        if (nome === '') {

            toast.error('Nome não pode ficar em branco!', { theme: 'colored' }) // - ok
            return false
        }


        if (crm === '') {

            toast.error('CRM não pode ficar em branco!', { theme: 'colored' }) // - ok
            return false
        }

        if (uf_codigo === '') {

            toast.error('UF não pode ficar em branco!', { theme: 'colored' }) // - ok
            return false
        }

        if (email === '') {

            toast.error('E-mail não pode ficar em branco!', { theme: 'colored' }) // - ok 
            return false
        }

        if (telefone === '') {

            toast.error('E-mail não pode ficar em branco!', { theme: 'colored' }) // - ok
            return false
        }


        if (senha !== confirmaSenha || '') {
            toast.error('As senhas não coincidem!', { theme: 'colored' });
            console.log('Erro: as senhas não coincidem');
            return false;
        }

      


        window.$.confirm({
            title: 'Confirmação',
            content: 'Confirma salvar?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter'], // Apenas enter como atalho
                    action: function () {
                        if (medico_id) { // Atualizar médico existente
                            var dataPost = {
                                cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                                nome: nome,
                                crm: crm,
                                uf_codigo: uf_codigo,
                                email: email,
                                telefone: telefone,
                                ad_usr: sessionStorage.getItem('usuario_id') // Certifique-se de que esse valor está preenchido corretamente
                            };
        
                            api.put(`medico/${medico_id}`, dataPost)
                                .then((result) => {
                                    var data = result.data.status;
                                    if (data === 'ok') {
                                        toast.success('Médico salvo com sucesso', { theme: "colored" });
                                        navigate('/Medico');
                                    } else {
                                        toast.error(data, { theme: 'colored' });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err.response);
                                    toast.error('Erro ao atualizar o médico.' + err.message, { theme: "colored" });
                                });
                        } else { // Criar novo médico
                            var dataPost = {
                                cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                                nome: nome,
                                senha: senha, // Certifique-se de que a senha está sendo definida corretamente
                                crm: crm,
                                uf_codigo: uf_codigo,
                                email: email,
                                telefone: telefone,
                                ad_usr: sessionStorage.getItem('usuario_id') // Certifique-se de que esse valor está preenchido corretamente
                            };
        
                            api.post(`medico`, dataPost)
                                .then((result) => {
                                    console.log(result.data)
                                    var data = result.data.status;
                                    if (data === 'ok') {
                                        toast.success('Médico salvo com sucesso', { theme: "colored" });
                                        navigate('/Medicos');
                                    } else {
                                        toast.error(data, { theme: 'colored' });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err.response)
                                    toast.error('Erro ao salvar o médico.' + err.message, { theme: "colored" });
                                });
                        }
                    }
                },
                cancel: {
                    text: 'Não',
                    btnClass: 'btn-red',
                    keys: ['esc'], // Alterado para a tecla esc como atalho para cancelar
                    action: function () {
                        // Ação ao cancelar
                        console.log('Cancelado pelo usuário');
                    },
                },
            }
        });
    }
        


    const carregaMedico = () => {
        api.get(`medico/${medico_id}`).then((result) => {

            const registro = result.data[0];
            setCpf(registro.cpf);
            setNome(registro.nome);
            setSenha(registro.senha);
            setCrm(registro.crm);
            setUf_codigo(registro.uf_codigo);
            setEmail(registro.email);
            setTelefone(registro.telefone);
            setAd_usr(registro.ad_usr);
        }).catch((err) => {
            console.log(err.response);
        });

    }



    const apagaMedico = () => {


        if (medico_id) {

            api.delete(`medico/${medico_id}`).then((result) => {

                console.log(result.data)
                toast.success('Medico deletado com sucesso', { theme: "colored" });

            }).catch((err) => {
                toast.error('Erro ao deletar o médico', { theme: "colored" });
                console.log()
            })

        }



    }


    const verificaCPF = () => {

        //console.log('aqui!')
        // chamar api que verifica se o CPF já é existente no banco de dados, tabela de médicos

        var dataPost = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            medico_id: medico_id,

        }

        //console.log(dataPost)

        api.post('medicoVerificaCPF', dataPost).then((result) => {

            if (result.data.status !== 'ok') {

                toast.error(result.data.status, { theme: 'colored' })

            }


        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

            
        carregaUf();

        if (medico_id) {

            carregaMedico()

        }
        
    }, [])





    return (

        <div>

            <div class="row">
                <div class="col-lg-12">

                    <div data-collapsed="0" class="card">

                        <div class="card-header">
                            <div class="card-title">

                                <h2 class="card-title">Médico</h2>

                            </div>
                        </div>

                        <div class="card-body">

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">CPF: *</label>
                                        <InputMask mask='999.999.999-99' type="text" class="form-control" value={cpf} onBlur={() => verificaCPF()} onChange={event => setCpf(event.target.value)} id="formGroupExampleInput" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">Nome: *</label>
                                        <input type="text" class="form-control" value={nome} onChange={event => setNome(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">CRM: *</label>
                                        <input type="text" class="form-control" value={crm} onChange={event => setCrm(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>


                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput4">
                                            UF *:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={uf_codigo}
                                            onChange={(event) => setUf_codigo(event.target.value)}
                                        >
                                            <option value="">[Selecione]</option>
                                            {listaUf}
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">E-mail: *</label>
                                        <input type="text" class="form-control" value={email} onChange={event => setEmail(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={255} />
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">Telefone: *</label>
                                        <input type="text" class="form-control" value={telefone} onChange={event => setTelefone(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={25} />
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">Senha: *</label>
                                        <input type="password" class="form-control" value={senha} onChange={event => setSenha(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={50} />
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="col-form-label" for="formGroupExampleInput">Confirmar senha: *</label>
                                        <input type="password" class="form-control" value={confirmaSenha} onChange={event => setConfirmaSenha(event.target.value)} id="formGroupExampleInput" placeholder="" maxLength={50} />
                                    </div>
                                </div>

                                <div class="col-lg-5">
                                    <br />
                                    <button type="button" class="btn btn-info mt-2" onClick={() => navigate('/Medicos')}>Voltar</button>&nbsp;
                                    <button type="button" class="btn btn-danger mt-2" onClick={() => apagaMedico()} style={{ display: medico_id ? 'table-row' : 'none' }}>Apagar</button>&nbsp;
                                    <button type="button" class="btn btn-success mt-2" onClick={() => salvaMedico()}>Salvar</button>

                                </div>



                            </div>

                        </div>

                    </div>
                </div>


            </div>

            <ToastContainer />

        </div>

    )

}



export default Medico