import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import api from "../components/api";
import { ToastContainer, toast } from 'react-toastify';


const Menu = () => {

    const [toggle, ] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isModalOpen, setModalOpen] = useState(false);

    const [confirmaEmail, setConfirmaEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [emailEnviado, setEmailEnviado] = useState(false)
    const [emailFaleConosco, setEmailFaleConosco] = useState('')
    const [nomeFaleConosco, setNomeFaleConosco] = useState('')
    const [assunto, setAssunto] = useState('')
    //const [ad_new, setAd_new] = useState('')


    const navigate = useNavigate()


    const visaLogin = sessionStorage.getItem('visaLogin')
    const usuario_id = sessionStorage.getItem('usuario_id')
    const emailLogin = sessionStorage.getItem('emailLogin')
    const nomeLogin = sessionStorage.getItem('nomeLogin')
    
    const logout = () => {

        //sessionStorage.clear()
        window.location.href = '/'


    }
    
    

    const faleConosco = () => {

    
        if (assunto === '') {
            toast.error('Por favor, selecione um assunto.', { theme: 'colored' });
            return false;
        }

    
        if (mensagem === '') {
            toast.error('Mensagem não pode ficar em branco.', { theme: 'colored' });
            return false;
        }




        window.$.confirm({

            title: 'Confirmação',
            content: 'Confirma o envio de mensagem?',
            buttons: {
                yes: {
                    text: 'Sim',
                    btnClass: 'btn-green',
                    keys: ['enter', 'shift'],
                    action: function () {
    
            
    
            var dataPost = {
                emailFaleConosco: emailLogin,
                nomeFaleConosco: nomeLogin,
                assunto: assunto,
                mensagem: mensagem,                
                
            }
            //console.log(dataPost)
            //return false
    
            api.post(`faleConosco`, dataPost).then((result) => {
            var data = result.data.status;
            var descricao = result.data.descricao;
            
            if (data === 'ok') {
              setEmailFaleConosco('');
              setNomeFaleConosco('');
              setAssunto('');
              setMensagem('');
              setEmailEnviado(true);


              /*console.log(dataPost)
              return false*/

              //toast.success('Mensagem Enviada.', { theme: "colored" });

              handleClose();
    
              //navigate('/AutoCadastro')

              window.$.confirm({

                title: 'Mensagem enviada!',
                content: 'Aguarde que entraremos em contato.',
                buttons: {
                        yes: {
                            text: 'Ok',
                            btnClass: 'btn-green',
                            keys: ['enter', 'shift'],
                            action: function () {
            
                    
                            }
                        },
                    
                    }
                });
                

            } else {
                toast.error(data + ' - ' + descricao, { theme: 'colored' });
            }
        })
        .catch((err) => {
            console.log(err.response);
            toast.error('Erro ao enviar o e-mail.', { theme: "colored" });
        });
        }
    },
    no: {
        text: 'Não',
        btnClass: 'btn-red',
        keys: ['esc'],
        action: function () {
            console.log('cancelado pelo usuário');
        },
    },
    }
    });
    }



    return (

        <div>
            <li>
                <Link class="nav-link" to='/Home'>
                    <i class="bx bx-home-alt" aria-hidden="true"></i>
                    <span>Início</span>
                </Link>
            </li>


            <li style={{ display: visaLogin == 'C' || visaLogin == 'G' || visaLogin == 'M' || visaLogin == 'A' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/UsuariosAnvisa'>
                    <i class="bx bxs-user" aria-hidden="true"></i>
                    <span>Usuários Anvisa </span>
                </Link>
            </li>

            <li style={{ display: visaLogin == 'C' || visaLogin == 'G' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/GestoresMunicipais'>
                    <i class="bx bxs-user-detail" aria-hidden="true"></i>
                    <span>Gestores Municipais </span>
                </Link>
            </li>

            <li style={{ display: 'none' }}>
                <Link class="nav-link" to='/Medicos'>
                    <i class="bx bx-plus-medical" aria-hidden="true"></i>
                    <span>Médicos</span>
                </Link>
            </li>


            <li style={{ display: 'none' }}>
                <Link class="nav-link" to='/Farmacias'>
                    <i class="bx bx-capsule" aria-hidden="true"></i>
                    <span>Farmácias</span>
                </Link>
            </li>


            <li style={{ display: 'none' }}>
                <Link class="nav-link" to='/NotificacaoRange'>
                    <i class="bx bx-list-ul" aria-hidden="true"></i>
                    <span>Notificação Range</span>
                </Link>
            </li>

            <li style={{ display: 'none' }}>
                <Link class="nav-link" to='/TermoNotificacoes'>
                    <i class="bx bx-bookmark-alt-plus" aria-hidden="true"></i>
                    <span>Termo de Notificação</span>
                </Link>
            </li>

            <li style={{ display: visaLogin == 'C' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/GestoresRegionais'>
                    <i class="bx bx-user-pin" aria-hidden="true"></i>
                    <span>Gestores Regionais</span>
                </Link>
            </li>

          
            <li style={{ display: visaLogin == 'C' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/ListaPublicacoes'>
                    <i class="bx bx-book-content" aria-hidden="true"></i>
                    <span>Ofícios ANVISA</span>
                </Link>
            </li>

            <li style={{ display: visaLogin == 'C' || visaLogin == 'G' || visaLogin == 'M' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/NovosCadastros'>
                    <i class="bx bx-info-square" aria-hidden="true"></i>
                    <span>Novos Cadastros</span>
                </Link>
            </li>


            <li style={{ display: visaLogin == 'C' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/MensagensFaleConosco'>
                    <i class="bx bx-support" aria-hidden="true"></i>
                    <span>Mensagens do Fale Conosco</span>
                </Link>
            </li>


            <li style={{ display: visaLogin === 'G' || visaLogin === 'M' ? 'table-row' : 'none' }}>
                <Link class="nav-link" onClick={handleShow}>
                    <i className="bx bx-user-voice" aria-hidden="true"></i>
                    <span>Fale Conosco</span>
                </Link>

                
            </li>


            <li style={{ display: visaLogin == 'C' ? 'table-row' : 'none' }}>
                <Link class="nav-link" to='/Usuarios'>
                    <i class="bx bx-user" aria-hidden="true"></i>
                    <span>Usuários</span>
                </Link>
            </li>
                    <li style={{ display: visaLogin == 'C' || visaLogin == 'G' || visaLogin == 'M' || visaLogin == 'A' ? 'table-row' : 'none' }}>
                <Link class="nav-link" onClick={() => logout()}>
                    <i class="bx bx-exit" aria-hidden="true"></i>
                    <span>Sair</span>
                </Link>
            </li>



                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title><b>Em caso de dúvida, nos envie uma mensagem.</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="col-form-label">Assunto: *</label>
                                        <select type="text" class="form-control" value={assunto} onChange={event => setAssunto(event.target.value)} placeholder="" maxLength={255} >
                                        <option value="">[Selecione]</option>
                                            <option value="Pre-Cadastro">Pre-Cadastro</option>
                                            </select>
                                    </div>
                                </div>

                        
                    {/*<div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="formGroupExampleInput3">E-mail: *</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="formGroupExampleInput3"
                                            value={emailFaleConosco}     
                                            onChange={(e) =>setEmailFaleConosco(e.target.value)}
                                            placeholder="Digite seu e-mail"
                                            maxLength={255}
                                        />
                                    </div>
                                </div>*/}
                               <br>
                               </br>
                            <div className="mt-3">
                               <b>Escreva sua mensagem:</b>  
                              <textarea
                              className="form-control mt-2"
                            rows="5"
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                            placeholder="Maxímo 1.000 caracteres"
                            maxLength={1000}
                        />
                           </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleClose}>
                            Fechar
                        </Button>
              
                        <Button  className="btn btn-success" 
                        
                        onClick={faleConosco}>
                            Enviar
                        </Button>
                    </Modal.Footer>
                    </Modal>

            <ToastContainer />



        </div >

    )

}

export default Menu