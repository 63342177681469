import { useState, useEffect } from 'react'
import api from "../components/api"
import { useNavigate } from "react-router-dom"


const Home = () => {

    const navigate = useNavigate()

    const [totalUsuariosAnvisa, setTotalUsuariosAnvisa] = useState('')
    const [resultado, setResultado] = useState('')
    


    const [dashboard, setDashboard] = useState(null);

    const perfil = sessionStorage.getItem('visaLogin')
    const nome = sessionStorage.getItem('nomeLogin')
    const visaLogin = sessionStorage.getItem('visaLogin')
    const ibge_codigoLogin = sessionStorage.getItem('ibge_codigoLogin')
    const gvs_codigoLogin = sessionStorage.getItem('gvs_codigoLogin')
  
    


    const carregaDashboard = () => {
        var dataPost = {
            visa: visaLogin,
            gvs_codigo: gvs_codigoLogin,
            ibge_codigo: ibge_codigoLogin,
        };
    
        api.post('dashboard', dataPost)
            .then((result) => {

                //console.log(result.data)
                //return false
                const registros = result.data[0];

                setDashboard({
                    totalUsuarios: registros.totalUsuario,  
                    totalUsuariosAnvisa: registros.totalUsuarioAnvisa,
                    totalPublicacao: registros.totalPublicacao,
                });
                //setResultado(registros);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };



    useEffect(() => {

        carregaDashboard();
    }, [visaLogin]);



    return (

        <div>

            <div class="row">

                <div class="col-lg-12">

                    <div class="row mb-6">
                        <div class="col-xl-12" style={{ marginBottom: 10}}>
                            <section class="card card-featured-left card-featured-secondary">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="">
                                                <i class=""></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h10 class="title">Bem vindo <b>{nome}</b>!</h10>
                                                <div class="info">
                                                </div>
                                            </div>
                                            <div class="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="col-xl-4" style={{ display: visaLogin === 'C' ? '' : 'none' }}>
                            <section class="card card-featured-left card-featured-primary mb-3">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-primary">
                                                <i class="fas fa-users"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">Usuários</h4>
                                                <div class="info">
                                                    <strong class="amount">{dashboard?.totalUsuarios ?? 'Carregando...'}</strong>
                                                </div>
                                            </div>
                                            <div class="summary-footer">
                                                <button type="button" className="btn btn-light mt-2" onClick={() => navigate('/Usuarios')}>Veja todos</button>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="col-xl-4" style={{ display: visaLogin === 'C' || visaLogin === 'G' || visaLogin === 'M' ? '' : 'none' }}>
                            <section class="card card-featured-left card-featured-secondary">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-secondary">
                                                <i class="fa-solid fa-notes-medical"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">Usuários Anvisa</h4>
                                                <div class="info">
                                                    <strong class="amount">{dashboard?.totalUsuariosAnvisa ?? 'Carregando...'}</strong>

                                                </div>
                                            </div>
                                            <div class="summary-footer">
                                                <button type="button" className="btn btn-light mt-2" onClick={() => navigate('/UsuariosAnvisa')}>Veja todos</button>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="col-xl-4" style={{ display: 'none' }}>
                            <div class="col-xl-12">
                                <section class="card card-featured-left card-featured-tertiary mb-3">
                                    <div class="card-body">
                                        <div class="widget-summary">
                                            <div class="widget-summary-col widget-summary-col-icon">
                                                <div class="summary-icon bg-success">
                                                    <i class="fas fa-hospital"></i>
                                                </div>
                                            </div>
                                            <div class="widget-summary-col">
                                                <div class="summary">
                                                    <h4 class="title">Farmácias</h4>
                                                    <div class="info">
                                                    <strong class="amount">{dashboard?.totalFarmacias ?? 'Carregando...'}</strong>
                                                    </div>
                                                </div>
                                                <div class="summary-footer">
                                                    <button type="button" className="btn btn-light mt-2" onClick={() => navigate('/Farmacias')}>Veja todos</button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div class="col-xl-4" style={{ display: 'none' }}>
                            <div class="col-xl-20">
                                <section class="card card-featured-left card-featured-quaternary">
                                    <div class="card-body">
                                        <div class="widget-summary">
                                            <div class="widget-summary-col widget-summary-col-icon">
                                                <div class="summary-icon bg-warning">
                                                    <i class="fa-solid fa-hospital-user"></i>
                                                </div>
                                            </div>
                                            <div class="widget-summary-col">
                                                <div class="summary">
                                                    <h4 class="title">Médicos</h4>
                                                    <div class="info">
                                                        <strong class="amount">{dashboard?.totalMedicos ?? 'Carregando...'}</strong>
                                                    </div>
                                                </div>
                                                <div class="summary-footer">
                                                    <button type="button" className="btn btn-light mt-2" onClick={() => navigate('/Medicos')}>Veja todos</button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>



                        <div class="col-xl-4" style={{ display: visaLogin === 'C' ? '' : 'none' }}>
                            <div class="col-xl-15">
                                <section class="card card-featured-left card-featured-tertiary mb-3">
                                    <div class="card-body">
                                        <div class="widget-summary">
                                            <div class="widget-summary-col widget-summary-col-icon">
                                                <div class="summary-icon bg-danger">
                                                    <i class="fa-regular fa-file"></i>
                                                </div>
                                            </div>
                                            <div class="widget-summary-col">
                                                <div class="summary">
                                                    <h4 class="title">Publicações</h4>
                                                    <div class="info">
                                                        <strong class="amount">{dashboard?.totalPublicacao ?? 'Carregando...'}</strong>
                                                    </div>
                                                </div>
                                                <div class="summary-footer">
                                                    <button type="button" className="btn btn-light mt-2" onClick={() => navigate('/ListaPublicacoes')}>Veja todos</button>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )

}



export default Home