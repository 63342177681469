import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { ToastContainer } from 'react-toastify';

///Lista todos os termos
///Lista um termo


const TermoNotificacoes = () => {

    const navigate = useNavigate();

    const columns = useMemo(() => [

        {
            accessorKey: 'termoNotificacao_id',
            header: 'Nº Termo de Notificação'
        },

        {
            accessorKey: 'nome',
            header: 'Nome',
        },

        {
            accessorKey: 'visa',
            header: 'VISA',
        },

        {
            accessorKey: 'gvs_descricao',
            header: 'GVS',
        },

    ], []);



    const { termoNotificacao_id } = useParams()

    const [numeroTermoNotificacao, setNumeroTermoNotificacao] = useState('')
    const [listaTermoNotificacao, setListaTermoNotificacao] = useState([])
    const [dadosTermoNotificacao, setDadosTermoNotificacao] = useState('')
    const [ad_new, setAd_new] = useState('')
    const [dadoTermoNotificacao, setDadoTermoNotificacao] = useState('')
    const [horaData, setHoraData] = useState('')
    const [totalTermosNotificacoes, setTotalTermosNotificacoes] = useState('')
    const [resultado, setResultado] = useState('')
 

    const carregaTermoNotificacao = () => {


        api.get(`termoNotificacao'`).then((result) => {

            const dadosTermoNotificacao = result.data[0][0]
            const resultado = result.data[1]

            setDadosTermoNotificacao(resultado)


            setAd_new(dadoTermoNotificacao.ad_new)
            setNumeroTermoNotificacao(dadoTermoNotificacao.numeroTermoNotificacao)
            setListaTermoNotificacao(listaTermoNotificacao)
            setDadoTermoNotificacao(dadoTermoNotificacao)


        }).catch((err) => {

            console.log(err.response)

        })


    }


   




useEffect(() => {

    if (termoNotificacao_id) {

        carregaTermoNotificacao()


    }

}, [termoNotificacao_id])



return (

    <div>

    <div class="row">
      <div class="col-lg-12">

        <div data-collapsed="0" class="card">

          <div class="card-header">
            <div class="card-title">

              <h2 class="card-title"> Listagem de Termos de Notificações</h2> 
              <br />
              <span className="badge badge-success">{totalTermosNotificacoes}</span>
            </div>
          </div>

          <div class="card-body">
            <button
              type="button"
              className="btn btn-warning mt-2"
              onClick={() => navigate('/TermoNotificacao')}
            >
              + Adicionar novo Termo de Notificação
            </button>
           
            &nbsp;

            <br /><br />
            <div class="row">


              <MaterialReactTable
                columns={columns}
                data={resultado}
                localization={MRT_Localization_PT_BR}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                  sx: {
                    fontSize: {
                      xs: '8px',
                      sm: '9px',
                      md: '10px',
                      lg: '11px',
                      xl: '12px',
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontSize: {
                      xs: '8px',
                      sm: '9px',
                      md: '10px',
                      lg: '11px',
                      xl: '12px',
                    },
                  },
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => navigate(`/termoNotificacaoPdf/${row.original.termoNotificacao_id}`),
                  sx: {
                    cursor: 'pointer',
                  },
                })}
              />




            </div>

          </div>

        </div>
      </div>


    </div>

    <ToastContainer />


  </div>



);
};


export default TermoNotificacoes
