import { useNavigate, useParams } from "react-router-dom"

const SenhaEnviadoEmail = () => {

    const { usuario_id } = useParams('') 

  
    const nomeLogin = sessionStorage.getItem('nomeLogin')

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div>
                <h2 className="text-center font-weight-bold text-6">
                    Olá{nomeLogin},
                    Senha provisoria enviada no endereço de e-mail informado. <br /><br />
                </h2>
            </div>
        </div>
    );
};

export default SenhaEnviadoEmail;