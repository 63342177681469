import { useState, useEffect, useMemo } from 'react'
import api from "../components/api"
import { useNavigate, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Usuarios = () => {

  const navigate = useNavigate();


  const columns = useMemo(() => [
    {
      accessorKey: 'nome',
      header: 'Nome',
    },
    {
      accessorKey: 'cpf',
      header: 'CPF',
    },
    {
      accessorKey: 'visa',
      header: 'Visa',
    },
    {
      accessorKey: 'email',
      header: 'E-mail',
    },
    {
      accessorKey: 'telefoneCelular',
      header: 'Telefone Celular',
    },
    {
      accessorKey: 'primeiroAcesso',
      header: 'Enviado e-mail de primeiro acesso?',
    },



  ], []);


  const [resultado, setResultadoUsuario] = useState([])
  const [totalUsuarios, setTotalUsuarios] = useState(0)


  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');
  const [status, setStatus] = useState('');
  const [visa, setVisa] = useState('');
  const [gvs_codigo, setGvsCodigo] = useState('');
  const [ibge_codigo, setIbgeCodigo] = useState('');
  const [TotalEmailsPendentes, setTotalEmailsPendentes] = useState('')
  const [ResultadoEmailsPendentes, setResultadoEmailsPendentes] = useState('')



  const apagaUsuario = (usuario_id) => {

    api.delete(`usuario/${usuario_id}`).then((result) => {

      console.log(result.data)
      carregaUsuarios()

    }).catch((err) => {

      console.log(err.response)

    })

  }


  /*   const usuarioExtraiExcel = () => {
  
      api.get(`usuarioExtraiExcel`).then((result) => {
  
        window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)
  
      }).catch((err) => {
  
        console.log(err.response)
  
      })
  
    } */




  const usuarioExtraiExcel = () => {
    api.get('usuarioExtraiExcel')
      .then((result) => {
        // Garante que o link utilize HTTPS para evitar erros de mixed content

        console.log('estamos aqui e o arquivo é: ' + result.data.arquivo)
        //return false

        const fileUrl = `https://apisecnr.saude.sp.gov.br/public/${result.data.arquivo}`;
        window.open(fileUrl, '_blank');

      })
      .catch((err) => {
        console.error(err.response);
      });
  };



  const listaTodosPendentes = () => {

    api.get(`emailsPendent`, dataPost)
      .then((result) => {
        const registros = result.data;
        setTotalEmailsPendentes(registros.length);
        setResultadoEmailsPendentes(registros);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };






  /*const UsuarioAlteraSenha = (usuario_id) => {

      api.update(`UsuarioAlteraSenha/${usuario_id}`).then((result) => {

      }).catch((err) => {

          console.log(err.response)

      })

  }*/

  const dataPost = {
    nome: nome,
    cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
    email: email,
    telefoneCelular: telefoneCelular,
    status: status,
    visa: visa,
    gvs_codigo: gvs_codigo,
    ibge_codigo: ibge_codigo,
  };


  const carregaUsuarios = () => {

    api.get('usuarios')
      .then((result) => {
        var registros = result.data

        //console.log(registros)

        setTotalUsuarios(registros.length)

        setResultadoUsuario(registros);
      });
  };


  useEffect(() => {

    carregaUsuarios()
    listaTodosPendentes()


  }, [])



  return (

    <div>

      <div className="row">
        <div className="col-lg-12">

          <div data-collapsed="0" className="card">

            <div className="card-header">
              <div className="card-title">

                <h2 className="card-title">Listagem de Usuários</h2>
                <br />
                <span className="badge badge-success">{totalUsuarios}</span>
              </div>
            </div>

            <div className="card-body">
              <button
                type="button"
                className="btn btn-warning mt-2"
                onClick={() => navigate('/Usuario')}

              >+ Novo usuário</button>

              &nbsp;


              <button
                type="button"
                className="btn btn-success mt-2"
                onClick={() => usuarioExtraiExcel()}
              >Exporta Excel</button>

              &nbsp;

              <br /><br />
              <div className="row">


                <div className="row">

                  <MaterialReactTable
                    columns={columns}
                    data={resultado}
                    localization={MRT_Localization_PT_BR}
                    initialState={{ density: 'compact' }}
                    muiTableHeadCellProps={{
                      sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: () => navigate(`/Usuario/${row.original.usuario_id}`),
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                  />


                </div>
              </div>
            </div>
          </div>

        </div>

        <ToastContainer />
      </div>
    </div>
  );
}


export default Usuarios